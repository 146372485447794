<template>
  <v-data-table
    :headers="headers"
    :items="recordings"
    @click:row="navigateToFloor"
  >
  </v-data-table>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import router from "@/router";
import store from "@/store";
import { RECORDINGS_FETCH_REQUEST } from "@/store/constants";

export default {
  data() {
    return {
      search: "",
      structure: "",
      structures_name: [],
      headers: [
        { text: "Label", align: "start", sortable: true, value: "label" },
        { text: "Id", align: "start", sortable: true, value: "numericId" },
      ],
    };
  },
  methods: {
    navigateToFloor(item) {
      router.push({
        name: "floorRecords",
        params: {
          buildingId: router.currentRoute.params.buildingId,
          floorId: item.numericId,
        },
      });
    },
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapState({
      recordings: (state) => state.recordings.recordings,
    }),
    ...mapGetters(["isLoading"]),
  },
  async beforeRouteEnter(to, from, next) {
    store.dispatch(RECORDINGS_FETCH_REQUEST, to.params.buildingId);
    next();
  },
};
</script>
